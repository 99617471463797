import { useEffect, useState } from 'react'
import { useParams, Link, useOutletContext, Navigate } from 'react-router-dom'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { fetch } from '../common/api'
import { enrollCourse } from '../common/interactions'
import { Typography, Container, Box, Grid, Button, Paper } from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { grey } from '@mui/material/colors'
import ImportContactsIcon from '@mui/icons-material/ImportContacts'
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'
import DownloadIcon from '@mui/icons-material/Download'
import ButtonModal from '../common/ButtonModal'
import LoginForm from '../account/LoginForm'
import HeroBanner from './HeroBanner'
import TopicStatusCircle from './TopicStatusCircle'
import NextTopicButton from './NextTopicButton'
import BreadCrumb from './BreadCrumb'
import WhatsNextCourses from './WhatsNextCourses'
import './Course.scss'

export default function Course() {
  const { id } = useParams()
  const { authed, user, isConfirmed, isApproved, isWhitelisted } =
    useOutletContext()
  const queryClient = useQueryClient()
  const [previewMode, setPreviewMode] = useState(false)
  const [interactionInProgress, setInteractionInProgress] = useState(false)
  const [course, setCourse] = useState(false)

  const headerHeight = {
    xs: '333px',
    sm: '413px',
    md: '423px',
    lg: '383px',
    xl: '483px'
  }

  const enroll = async (userId, courseId) => {
    if (!isConfirmed || (!isApproved && !isWhitelisted)) return

    setInteractionInProgress(true)
    try {
      await enrollCourse(userId, courseId)
      setInteractionInProgress(false)
      const today = new Date(Date.now())
      const courseUpdates =
        course.enrolledAt || course.percentCompleted === 100
          ? {
              enrolledAt: today.toLocaleDateString(),
              percentCompleted: 0,
              topics: course.topics.map((t) => ({
                ...t,
                ...{ isStarted: false, isCompleted: false }
              }))
            }
          : { enrolledAt: today.toLocaleDateString() }

      const newCourse = {
        ...course,
        ...courseUpdates
      }
      queryClient.setQueryData(['course', { id }], newCourse)

      setCourse(newCourse)
    } catch (e) {
      console.log(e)
      setInteractionInProgress(false)
    }
  }

  const courseTime = (course) =>
    course.topics
      ? course.topics.reduce((a, t) => a + parseInt(t.estimatedTime ?? 0), 0)
      : 0

  const courseTimeCompleted = (course) =>
    course.topics
      ? course.topics
          .filter((t) => t.isCompleted)
          .reduce((a, t) => a + parseInt(t.estimatedTime ?? 0), 0)
      : 0

  const useCourse = (courseId) => {
    return useQuery(
      ['course', courseId],
      async () => {
        return await fetch(`/courses/${courseId}?related=topics`)
      },
      { useErrorBoundary: (error) => error.response?.status >= 500 }
    )
  }

  const { status, data: courseData, error } = useCourse(id)

  const topicAction = (t) => {
    return (
      <>
        {previewMode ? (
          <>
            <Button
              component={Link}
              size="small"
              variant="contained"
              sx={{
                textTransform: 'initial',
                width: { xs: true, sm: 120 }
              }}
              aria-label="preview topic"
              to={`/courses/${course.slug}/${t.slug}`}
            >
              Preview
            </Button>
          </>
        ) : (
          <>
            {!course.percentCompleted && !course.enrolledAt ? (
              <>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    textTransform: 'initial',
                    width: { xs: true, sm: 145 }
                  }}
                  disabled={true}
                  aria-label="enroll topic"
                >
                  {interactionInProgress ? 'Enrolling...' : 'Please enroll'}
                </Button>
              </>
            ) : (
              <>
                {!t.isStarted && !t.isCompleted ? (
                  <>
                    <Button
                      component={Link}
                      size="small"
                      variant="contained"
                      sx={{
                        textTransform: 'initial',
                        width: { xs: true, sm: 120 }
                      }}
                      disabled={
                        interactionInProgress ||
                        !isConfirmed ||
                        (!isApproved && !isWhitelisted)
                      }
                      aria-label="start topic"
                      to={`/courses/${course.slug}/${t.slug}`}
                    >
                      {interactionInProgress ? 'Enrolling...' : 'Start'}
                    </Button>
                  </>
                ) : (
                  <>
                    {t.isCompleted ? (
                      <Button
                        component={Link}
                        size="small"
                        color="trinary"
                        variant="outlined"
                        disabled={interactionInProgress}
                        sx={{
                          textTransform: 'initial',
                          backgroundColor: '#fff',
                          width: { xs: true, sm: 120 },
                          boxShadow:
                            '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
                        }}
                        aria-label="restart topic"
                        to={`/courses/${course.slug}/${t.slug}`}
                      >
                        {interactionInProgress ? 'Enrolling...' : 'Restart'}
                      </Button>
                    ) : (
                      <Button
                        component={Link}
                        size="small"
                        color="secondary"
                        variant="outlined"
                        sx={{
                          backgroundColor: '#fff',
                          textTransform: 'initial',
                          width: { xs: true, sm: 120 },
                          boxShadow:
                            '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
                        }}
                        aria-label="continue topic"
                        to={`/courses/${course.slug}/${t.slug}`}
                      >
                        Continue
                      </Button>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    )
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    setCourse(courseData)
    if (sessionStorage.getItem('previewMode')) setPreviewMode(true)
    return () => {}
  }, [id, authed, previewMode, courseData])

  return (
    <>
      {error?.response?.status === 404 ? (
        <Navigate to="/404" replace />
      ) : undefined}
      <HeroBanner
        image={
          status !== 'loading' && course?.imageUrl !== undefined
            ? `${course.imageUrl
                .replace('_orig', '')
                .replace('.jpeg', '.jpg')
                .replace('.png', '.jpg')
                .replace('.jpg', '_960w.jpg')}`
            : null
        }
        imageLocation="right"
        height={headerHeight}
      >
        {previewMode ? (
          <Typography
            variant="eyebrow"
            color="secondary.light"
            sx={{
              mb: 1
            }}
          >
            PREVIEW MODE
          </Typography>
        ) : (
          <>
            {status === 'success' &&
            course?.enrolledAt &&
            course?.percentCompleted !== 100 ? (
              <Typography
                variant="eyebrow"
                color="secondary.light"
                sx={{
                  mb: 1
                }}
              >
                Enrolled
              </Typography>
            ) : undefined}

            {status === 'success' &&
            course?.percentCompleted &&
            course?.percentCompleted === 100 ? (
              <Typography
                variant="eyebrow"
                color="secondary.light"
                sx={{
                  mb: 1
                }}
              >
                Completed
              </Typography>
            ) : undefined}
          </>
        )}
        {course && status === 'success' ? (
          <>
            <Typography
              variant="h1"
              color="white"
              sx={{
                mb: 3
              }}
            >
              {course?.name}
            </Typography>
            <Typography variant="body" paragraph color="white">
              {course?.description}
            </Typography>
            {authed && isConfirmed && (isApproved || isWhitelisted) ? (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center', my: 3 }}>
                  <Typography
                    variant="h5"
                    color="white"
                    sx={{ mr: 1, paddingTop: '5px' }}
                  >
                    Spent
                  </Typography>
                  <AccessTimeIcon sx={{ color: '#fff', mr: 1 }} />
                  <Typography variant="body" color="white">
                    {courseTimeCompleted(course)} min
                  </Typography>
                  <Typography
                    variant="h5"
                    color="white"
                    sx={{ ml: 2, mr: 1, paddingTop: '5px' }}
                  >
                    Left
                  </Typography>
                  <AccessTimeIcon sx={{ color: '#fff', mr: 1 }} />
                  <Typography variant="body" color="white">
                    {courseTime(course) - courseTimeCompleted(course)} min
                  </Typography>
                </Box>
                {previewMode ? (
                  <>
                    <NextTopicButton
                      course={course}
                      id="start-your-learning-experience"
                      color="secondaryDark"
                      sx={{
                        mt: 1,
                        px: 4,
                        width: { xs: '100%', sm: 'initial' }
                      }}
                    >
                      Start your learning experience!
                    </NextTopicButton>
                  </>
                ) : (
                  <>
                    {!course.percentCompleted && course.enrolledAt ? (
                      <NextTopicButton
                        course={course}
                        id="start-your-learning-experience"
                        color="secondaryDark"
                        sx={{
                          mt: 1,
                          px: 4,
                          width: { xs: '100%', sm: 'initial' }
                        }}
                      >
                        Start your learning experience!
                      </NextTopicButton>
                    ) : undefined}

                    {course.percentCompleted &&
                    course.percentCompleted < 100 ? (
                      <NextTopicButton
                        course={course}
                        id="continue-where-left"
                        color="secondaryDark"
                        sx={{
                          mt: 1,
                          px: 4,
                          width: { xs: '100%', sm: 'initial' }
                        }}
                      >
                        Continue where you left off
                      </NextTopicButton>
                    ) : undefined}

                    {course.percentCompleted >= 100 ? (
                      <Button
                        id="re-enroll"
                        variant="contained"
                        color="secondaryDark"
                        disabled={interactionInProgress}
                        onClick={() => enroll(user.sub, course.id)}
                        sx={{
                          mt: 1,
                          px: 4,
                          width: { xs: '100%', sm: 'initial' }
                        }}
                      >
                        {interactionInProgress
                          ? 'Enrolling...'
                          : 'Enroll to Restart this course'}
                      </Button>
                    ) : undefined}

                    {!course.percentCompleted && !course.enrolledAt ? (
                      <Button
                        id="enroll-now-button"
                        color="secondaryDark"
                        variant="contained"
                        sx={{
                          mt: 1,
                          px: 4,
                          width: { xs: '100%', sm: 'initial' }
                        }}
                        disabled={interactionInProgress}
                        onClick={() => enroll(user.sub, course.id)}
                      >
                        {interactionInProgress ? 'Enrolling...' : 'Enroll Now'}
                      </Button>
                    ) : undefined}
                  </>
                )}
              </>
            ) : (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center', my: 3 }}>
                  <Typography variant="h5" color="white" sx={{ mr: 1 }}>
                    Estimated time
                  </Typography>
                  <AccessTimeIcon sx={{ color: '#fff', mr: 1 }} />
                  <Typography variant="h5" color="white">
                    {courseTime(course)} min
                  </Typography>
                </Box>
                {!isConfirmed && authed ? (
                  <Button
                    id="enroll-now-button"
                    color="secondaryDark"
                    variant="contained"
                    sx={{ mt: 3, width: { xs: '100%', sm: 'initial' } }}
                  >
                    Confirm your email to continue
                  </Button>
                ) : !isApproved && !isWhitelisted && authed ? (
                  <Button
                    id="enroll-now-button"
                    color="secondaryDark"
                    variant="contained"
                    sx={{ mt: 3, width: { xs: '100%', sm: 'initial' } }}
                  >
                    Approval Pending
                  </Button>
                ) : (
                  <ButtonModal
                    size="lg"
                    component={
                      <Button
                        id="enroll-now-button"
                        color="secondaryDark"
                        variant="contained"
                        sx={{ mt: 3, width: { xs: '100%', sm: 'initial' } }}
                      >
                        Login to Start
                      </Button>
                    }
                  >
                    <LoginForm />
                  </ButtonModal>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <Box
              className="is-loading"
              sx={{
                minHeight: '70px',
                width: '350px',
                mb: 3
              }}
            ></Box>
            <Box
              className="is-loading"
              sx={{
                minHeight: '80px',
                width: '550px',
                mb: 2
              }}
            ></Box>
            <Box
              className="is-loading"
              sx={{
                minHeight: '40px',
                width: '350px',
                mb: 2
              }}
            ></Box>
          </>
        )}
      </HeroBanner>

      <Container component="section" maxWidth="xl" sx={{ pt: 4 }}>
        <BreadCrumb />
      </Container>
      <Container component="section">
        <Grid container justifyContent="center">
          <Grid item xs={12} md={10}>
            <Typography
              variant="h2"
              sx={{
                my: { xs: 0, md: 1, lg: 5 }
              }}
            >
              Topics Covered
            </Typography>

            {status === 'loading' ? (
              [...Array(8)].map((_, i) => (
                <Paper
                  key={`empty-topic-card-${i}`}
                  elevation={0}
                  className={('topic-card', 'is-loading')}
                  sx={{
                    p: 1,
                    my: 2,
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                      opacity: 0.25
                    }}
                  >
                    <ImportContactsIcon sx={{ mr: 2, ml: 1 }} />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      opacity: 0.25
                    }}
                  >
                    <DownloadIcon sx={{ mr: 3 }} />
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      disabled
                      sx={{
                        textTransform: 'initial',
                        px: 3
                      }}
                    >
                      Start
                    </Button>
                  </Box>
                </Paper>
              ))
            ) : status === 'error' ? (
              <span>Error: {error.message}</span>
            ) : course?.topics ? (
              <>
                {course.topics
                  .filter((t) => t.id !== undefined)
                  .map((t) => (
                    <Paper
                      elevation={0}
                      key={t.id}
                      className={'topic-card'}
                      sx={{
                        p: 1,
                        my: 2,
                        backgroundColor: grey[100]
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {t.attachments &&
                          t.attachments.find((a) => a.kind === 'video') ? (
                            <OndemandVideoIcon sx={{ mr: 2, ml: 1 }} />
                          ) : (
                            <ImportContactsIcon sx={{ mr: 2, ml: 1 }} />
                          )}
                          <Typography
                            variant="h6"
                            sx={{
                              textTransform: 'uppercase'
                            }}
                          >
                            {t.name}
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {t.attachments &&
                          t.attachments.find((a) => a.kind === 'pdf') ? (
                            <DownloadIcon sx={{ mr: 3 }} />
                          ) : undefined}

                          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                            {topicAction(t)}
                          </Box>
                          <TopicStatusCircle
                            topic={t}
                            previewMode={previewMode}
                          />
                        </Box>
                      </Box>
                      <Box sx={{ mt: 1, display: { xs: 'block', sm: 'none' } }}>
                        {topicAction(t)}
                      </Box>
                    </Paper>
                  ))}
              </>
            ) : undefined}
          </Grid>
        </Grid>
        {status === 'success' &&
        course?.id &&
        isConfirmed &&
        (isApproved || isWhitelisted) ? (
          <WhatsNextCourses courseId={course.id} authed={authed} />
        ) : undefined}
      </Container>
    </>
  )
}
