import { useState, cloneElement, useEffect } from 'react'
import { cancel as cancelRequest, fetch } from '../common/api'
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { Auth, Hub } from 'aws-amplify'
import LoginPrompt from './LoginPrompt'

const adminRole = 'administrator'
const superAdminRole = 'super_administrator'

const userQuery = (userId) => ({
  queryKey: ['user', userId],
  queryFn: async () => {
    return await fetch(`/users/${userId}`)
  }
})

const isSuperAdminInGroups = (groups) =>
  groups.some((g) => g.toLowerCase() === superAdminRole)

const isAnyTypeOfAdminInGroups = (groups) =>
  groups.some(
    (g) => g.toLowerCase() === adminRole || g.toLowerCase() === superAdminRole
  )

function useAuth() {
  const location = useLocation()
  const queryClient = useQueryClient()
  const [authed, setAuthed] = useState(false)
  const [user, setUser] = useState(null)
  const [isAdmin, setAdmin] = useState(null)
  const [isSuperAdmin, setSuperAdmin] = useState(null)

  const getToken = () =>
    Auth.currentSession()
      .then((session) => session)
      .catch((err) => console.error(err))

  const setupAuthorization = async (token) => {
    if (!token || token === undefined) {
      setAuthed(false)
      setUser(null)
      setAdmin(false)
      setSuperAdmin(false)
      localStorage.removeItem('user')
      return
    }

    const isOktaProvider = (identities) =>
      identities.some(
        (i) =>
          i?.providerName?.toLowerCase() ===
          process.env.REACT_APP_COGNITO_OKTA_PROVIDER_NAME?.toLowerCase()
      )

    const cognitoGroups = token.idToken
      ? token.idToken.payload['cognito:groups'] ?? []
      : token.signInUserSession.idToken.payload['cognito:groups'] ?? []

    const user = token.idToken
      ? {
          sub: token.idToken.payload.sub,
          email: token.idToken.payload.email,
          given_name: token.idToken.payload.given_name,
          family_name: token.idToken.payload.family_name,
          user_role: isAnyTypeOfAdminInGroups(cognitoGroups)
            ? token.idToken.payload['custom:user_role']
            : 'student',
          token: token.idToken.jwtToken,
          company_name: token.idToken.payload['custom:company_name'],
          email_verified: token.idToken.payload.email_verified,
          is_okta: token.idToken.payload['identities']
            ? isOktaProvider(token.idToken.payload['identities'])
            : false
        }
      : {
          sub: token.signInUserSession.idToken.payload.sub,
          email: token.signInUserSession.idToken.payload.email,
          given_name: token.signInUserSession.idToken.payload.given_name,
          family_name: token.signInUserSession.idToken.payload.family_name,
          user_role: isAnyTypeOfAdminInGroups(cognitoGroups)
            ? token.signInUserSession.idToken.payload['custom:user_role']
            : 'student',
          token: token.signInUserSession.idToken.jwtToken,
          company_name:
            token.signInUserSession.idToken.payload['custom:company_name'],
          email_verified:
            token.signInUserSession.idToken.payload.email_verified,
          is_okta: token.signInUserSession.idToken.payload['identities']
            ? isOktaProvider(
                token.signInUserSession.idToken.payload['identities']
              )
            : false
        }

    const isAnAdmin = isAnyTypeOfAdminInGroups(cognitoGroups)

    setAdmin(isAnAdmin)
    setSuperAdmin(isSuperAdminInGroups(cognitoGroups))
    setAuthed(true)
    localStorage.setItem('user', JSON.stringify(user))
    setUser(user)

    queryClient.getQueryData(['user', user.sub]) ??
      (await queryClient.prefetchQuery(userQuery(user.sub)))

    const u = await fetch(`/users/${user.sub}`)

    const updatedUser = {
      ...user,
      ...{
        is_approved: isAnAdmin || (u.isApproved ?? false),
        is_whitelisted: isAnAdmin || (u.isWhitelisted ?? false)
      }
    }
    setUser(updatedUser)
    localStorage.setItem('user', JSON.stringify(updatedUser))
  }

  useEffect(() => {
    let mounted = true
    let authUserRequest

    const loggedInUser =
      localStorage.getItem('user') !== null
        ? JSON.parse(localStorage.getItem('user'))
        : false

    async function recheckAdminSession() {
      try {
        authUserRequest = Auth.currentSession()
        const session = await authUserRequest
        const cognitoGroups = session.idToken.payload['cognito:groups'] ?? []

        setAdmin(isAnyTypeOfAdminInGroups(cognitoGroups))
        setSuperAdmin(isSuperAdminInGroups(cognitoGroups))

        const recheckedUser = {
          ...loggedInUser,
          ...{
            user_role: isAnyTypeOfAdminInGroups(cognitoGroups)
              ? loggedInUser.user_role
              : 'student'
          }
        }
        setUser(recheckedUser)
        localStorage.setItem('user', JSON.stringify(recheckedUser))
      } catch (e) {
        console.log(e)
      }
    }

    if (loggedInUser && mounted) {
      setAuthed(true)

      if (location?.pathname.indexOf('/admin') >= 0) {
        recheckAdminSession()
      } else {
        setAdmin(isAnyTypeOfAdminInGroups([loggedInUser.user_role]))
        setSuperAdmin(loggedInUser.user_role === superAdminRole)
        setUser(loggedInUser)
      }
    }

    return () => {
      mounted = false
      cancelRequest(authUserRequest)
    }
  }, [location])

  return {
    authed,
    user,
    isAdmin,
    isSuperAdmin,
    loginOktaUrl() {
      Auth.federatedSignIn({
        provider: process.env.REACT_APP_COGNITO_OKTA_PROVIDER_NAME
      })
    },
    login(username, password, token) {
      return Auth.signIn(username.trim(), password, { captcha: token }).catch(
        (e) => {
          setupAuthorization(false)
          throw e
        }
      )
    },
    logout() {
      return Auth.signOut()
    },
    reRefreshAuth() {
      return getToken().then((userToken) => {
        setupAuthorization(userToken)
      })
    },
    updateAuthorization(userToken) {
      setupAuthorization(userToken)
    },
    reCheckAuth() {
      return Auth.currentAuthenticatedUser({
        bypassCache: true
      }).catch((err) => console.error(err))
    },
    resendConfirmationEmail(username) {
      return Auth.resendSignUp(username)
    },
    getCurrentAuthenticatedUser() {
      return Auth.currentAuthenticatedUser()
    },
    verifyUsersEmail(user) {
      return Auth.verifyUserAttribute(user, 'email')
    },
    confirmUserEmailCode(code) {
      return Auth.verifyCurrentUserAttributeSubmit('email', code)
    },
    watchAuthEvents() {
      const handleAuth = ({ payload: { event, data } }) => {
        console.log(event)
        switch (event) {
          case 'signIn':
          case 'cognitoHostedUI':
            setupAuthorization(data.signInUserSession)
            break
          case 'signOut':
            setupAuthorization(false)
            break
          case 'tokenRefresh':
            this.reRefreshAuth()
            break
          case 'signUp_failure':
          case 'signUp':
          case 'forgotPassword':
          case 'forgotPasswordSubmit':
          case 'forgotPassword_failure':
          case 'tokenRefresh_failure':
            break
          case 'signIn_failure':
          case 'cognitoHostedUI_failure':
          default:
            setupAuthorization(false)
            break
        }
      }

      Hub.listen('auth', handleAuth)

      return () => Hub.remove('auth', handleAuth)
    }
  }
}

export { useAuth }

export function RequireAuth({ children, forceLogin, adminOnly }) {
  const location = useLocation()
  const auth = useAuth()
  const queryClient = useQueryClient()
  const [searchParams] = useSearchParams()
  const [loggedInUser, setLoggedInUser] = useState(false)
  const navigate = useNavigate()
  const [child, setChild] = useState(
    cloneElement(children, {
      authed: false,
      user: null
    })
  )
  const hasUserStorage = localStorage.getItem('user')

  useEffect(() => {
    let mounted = true
    let authUserRequest

    if (searchParams.get('preview') !== null)
      sessionStorage.setItem('previewMode', true)

    if (auth?.user?.sub)
      queryClient.getQueryData(['user', auth.user.sub]) ??
        queryClient.prefetchQuery(userQuery(auth.user.sub))

    async function fetchData() {
      try {
        authUserRequest = Auth.currentSession()
        const session = await authUserRequest
        const cognitoGroups = session.idToken.payload['cognito:groups'] ?? []
        const sessionUser = session.idToken.payload
        const u = await fetch(`/users/${session.idToken.payload.sub}`)
        const user = {
          ...sessionUser,
          ...{
            is_approved:
              isAnyTypeOfAdminInGroups(cognitoGroups) ||
              (u.isApproved ?? false),
            is_whitelisted:
              isAnyTypeOfAdminInGroups(cognitoGroups) ||
              (u.isWhitelisted ?? false),
            ['custom:user_role']: isAnyTypeOfAdminInGroups(cognitoGroups)
              ? u.user_role
              : 'student',
            user_role: isAnyTypeOfAdminInGroups(cognitoGroups)
              ? u.user_role
              : 'student'
          }
        }

        if (adminOnly && !isAnyTypeOfAdminInGroups(cognitoGroups)) navigate('/')

        if (mounted) {
          setChild(
            cloneElement(children, {
              authed: true,
              user: user
            })
          )
          setLoggedInUser(true)
        }
      } catch (e) {
        console.error(e)
        if (mounted) {
          if (adminOnly) navigate('/')

          setLoggedInUser(false)
          setChild(
            cloneElement(children, {
              authed: false,
              user: null
            })
          )
        }
      }
    }

    if (!auth?.user?.sub) fetchData()
    else {
      if (mounted) {
        setChild(
          cloneElement(children, {
            authed: true,
            user: auth?.user
          })
        )
        setLoggedInUser(true)
      }
    }

    return () => {
      mounted = false
      cancelRequest(authUserRequest)
    }
  }, [
    auth.user,
    hasUserStorage,
    children,
    searchParams,
    queryClient,
    adminOnly,
    navigate
  ])

  useEffect(() => {
    const handleAuth = ({ payload: { event, data } }) => {
      console.log(event)
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          setLoggedInUser(true)
          setChild(
            cloneElement(children, {
              authed: true,
              user: data.signInUserSession.idToken.payload
            })
          )
          break
        case 'forgotPassword':
        case 'forgotPasswordSubmit':
        case 'forgotPassword_failure':
        case 'signUp_failure':
        case 'signUp':
        case 'tokenRefresh':
        case 'tokenRefresh_failure':
          break
        default:
          setLoggedInUser(false)
          setChild(
            cloneElement(children, {
              authed: false,
              user: null
            })
          )
          console.log('Authorization expired', data)
          break
      }
    }

    Hub.listen('auth', handleAuth)
    return () => Hub.remove('auth', handleAuth)
  })

  if (
    (!loggedInUser && adminOnly) ||
    (loggedInUser &&
      auth.user &&
      !isAnyTypeOfAdminInGroups([auth?.user?.user_role]) &&
      adminOnly)
  )
    return <></>

  return loggedInUser || !forceLogin ? (
    child
  ) : (
    <LoginPrompt returnPath={location.pathname} />
  )
}

RequireAuth.propTypes = {
  forceLogin: PropTypes.bool,
  adminOnly: PropTypes.bool,
  children: PropTypes.element.isRequired
}

RequireAuth.defaultProps = {
  forceLogin: false
}
