import { useEffect, useState } from 'react'
import {
  useParams,
  useOutletContext,
  Link,
  useNavigate,
  Navigate
} from 'react-router-dom'
import Truncate from 'react-truncate'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { fetch, update } from '../common/api'
import { Typography, Container, Box, Grid, Paper, Button } from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { grey } from '@mui/material/colors'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import GlobalStyles from '@mui/material/GlobalStyles'
import CourseProgressCircle from './CourseProgressCircle'
import TopicStatusCircle from './TopicStatusCircle'
import NextTopicButton from './NextTopicButton'
import BreadCrumb from './BreadCrumb'
import WhatsNextCourses from './WhatsNextCourses'
import PreviousTopicButton from './PreviousTopicButton'
import TopicPdf from '../admin/TopicPdf'
import TopicXapi from '../admin/TopicXapi'
import TopicVideo from '../admin/TopicVideo'
import HeroBanner from './HeroBanner'
import './Topic.scss'

export default function Topic() {
  const { courseId, id } = useParams()
  const { authed, user, isConfirmed } = useOutletContext()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [interactionInProgress, setInteractionInProgress] = useState(false)
  const [videoCompleted, setVideoCompleted] = useState(false)
  const [totalCompletedTopics, setTotalCompletedTopics] = useState(0)
  const [timeSpent, setTimeSpent] = useState(0)
  const [timeLeft, setTimeLeft] = useState(0)
  const [previewMode, setPreviewMode] = useState(false)
  const [course, setCourse] = useState(false)
  const [topic, setTopic] = useState(false)

  const handleVideoEnd = () => {
    setVideoCompleted(true)
  }

  const handleXapiReload = (topic) => {
    //setTopic(topic)
    console.log(topic)
  }

  const createInteraction = async (type, userId, courseId, payload) => {
    setInteractionInProgress(true)
    try {
      await update(`/users/${userId}/interactions`, {
        interactionType: type,
        courseId: courseId,
        ...(payload ? payload : {})
      })
      setInteractionInProgress(false)
    } catch (e) {
      setInteractionInProgress(false)
    }
  }

  const completeLastTopic = async (userId, courseId, topicId) => {
    await completeTopic(userId, courseId, topicId)
    navigate(`/courses/${course.slug}`)
  }

  const completeTopic = async (userId, courseId, topicId) => {
    setTopic(false)
    setCourse(false)
    setVideoCompleted(false)
    const topicIndex = course.topics.findIndex((t) => t.id === topicId)

    const newCourse = {
      ...course,
      topics: course.topics.map((t, index) => {
        return index === topicIndex
          ? {
              ...t,
              ...{ isCompleted: true }
            }
          : t
      })
    }

    const { totalCompletedCourses, timeSpent, timeLeft } =
      recalcuateCourseStats(newCourse)

    setTotalCompletedTopics(totalCompletedCourses)
    setTimeSpent(timeSpent)
    setTimeLeft(timeLeft)

    await createInteraction('topic_completed', userId, courseId, {
      topicId: topicId
    })

    await createInteraction('course_progression', userId, courseId, {
      percentCompleted: (totalCompletedCourses / newCourse.topics.length) * 100
    })

    queryClient.setQueryData(['course', courseId], newCourse)
    setCourse(newCourse)
  }

  const recalcuateCourseStats = (course) => {
    const time = course.topics.reduce(
      (a, t) => a + parseInt(t.estimatedTime ?? 0),
      0
    )
    const timeCompleted = course.topics
      .filter((t) => t?.isCompleted)
      .reduce((a, t) => a + parseInt(t.estimatedTime ?? 0), 0)

    const totalCompletedCourses = course.topics
      .filter((t) => t?.isCompleted === true)
      .reduce((a) => a + 1, 0)

    return {
      totalCompletedCourses: totalCompletedCourses,
      timeSpent: timeCompleted,
      timeLeft: time - timeCompleted
    }
  }

  const formatHTml = (html) =>
    html
      .replace(/data-imageloader-src/g, 'src')
      .replace(/class="ql-align-center"/g, 'style="text-align:center"')

  const headerHeight = {
    xs: '250px',
    md: '320px',
    lg: '320px',
    xl: '320px'
  }

  const completeOrContinueButtons = () => {
    return (
      <>
        {previewMode && status === 'success' && topic && course ? (
          <NextTopicButton
            course={course}
            topicId={topic?.id}
            id="complete-and-continue"
            color="black"
            fullWidth
            sx={{ mb: 1, py: 2 }}
          >
            Continue Preview
          </NextTopicButton>
        ) : (
          <>
            {topic &&
            course &&
            status === 'success' &&
            courseStatus === 'success' &&
            course.enrolledAt ? (
              <NextTopicButton
                course={course}
                topicId={topic?.id}
                disabled={interactionInProgress || !topic?.id}
                id="complete-and-continue"
                color="black"
                fullWidth
                sx={{ mb: 1 }}
                onClick={() => {
                  !topic?.isCompleted &&
                    completeTopic(user.sub, course.id, topic.id)
                }}
              >
                {topic?.isCompleted ? (
                  <>{'Continue'}</>
                ) : (
                  <>{'Complete and Continue'}</>
                )}
              </NextTopicButton>
            ) : (
              <Button
                fullWidth
                id="complete-course"
                disabled
                sx={{ mb: 1, py: 2 }}
                color="black"
                variant="contained"
              >
                You must enrol first to continue
              </Button>
            )}

            {status === 'success' &&
            course?.topics &&
            !topic?.isCompleted &&
            totalCompletedTopics === course?.topics?.length - 1 &&
            topic.attachments[0].kind !== 'xapi_quiz' ? (
              <Button
                fullWidth
                id="complete-course"
                disabled={interactionInProgress}
                sx={{ mb: 1, py: 2 }}
                color="primary"
                variant="contained"
                onClick={() => {
                  completeLastTopic(user.sub, course.id, topic.id)
                }}
              >
                Complete Course
              </Button>
            ) : undefined}
          </>
        )}

        {status === 'success' &&
        courseStatus === 'success' &&
        course &&
        topic?.id ? (
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <PreviousTopicButton
              id="previous-topic"
              course={course}
              color="secondary"
              sx={{ mb: 1, py: 2 }}
              topicId={topic?.id}
              fullWidth
            >
              Previous topic
            </PreviousTopicButton>
          </Box>
        ) : undefined}
      </>
    )
  }

  const topicEndpoint = `/courses/${courseId}/topics/${id}?related=attachments`

  const topicQuery = (courseId, topicId) => ({
    queryKey: ['topic', courseId, topicId],
    queryFn: async () => await fetch(topicEndpoint),
    //onSuccess: async (topic) => {
    //setTopic(topic)
    //},
    useErrorBoundary: (error) => error.response?.status >= 500
  })

  const courseQuery = (courseId) => ({
    queryKey: ['course', courseId],
    queryFn: async () => fetch(`/courses/${courseId}?related=topics`),
    onSuccess: (course) => {
      const setTotals = (course) => {
        if (previewMode) return
        const totals = recalcuateCourseStats(course)

        setTotalCompletedTopics(totals.totalCompletedCourses)
        setTimeSpent(totals.timeSpent)
        setTimeLeft(totals.timeLeft)
      }

      const startTopic = async (userId, courseId, topicId) => {
        await createInteraction('topic_started', userId, courseId, {
          topicId: topicId
        })
      }

      const enrollCourse = async (userId, courseId) => {
        await createInteraction('course_enrollment', userId, courseId)
      }

      //setCourse(course)

      if (!course.enrolledAt && isConfirmed) {
        enrollCourse(user.sub, course?.id)
        const today = new Date(Date.now())

        const newCourse = {
          ...course,
          ...{ enrolledAt: today.toLocaleDateString() }
        }

        queryClient.setQueryData(['course', courseId], newCourse)
        setCourse(newCourse)
      }

      if (
        status === 'success' &&
        !topic?.isStarted &&
        !topic?.isCompleted &&
        course?.enrolledAt
      ) {
        if (
          !previewMode &&
          isConfirmed &&
          topic.attachments.find(
            (a) => a.kind !== 'quiz' && a.kind !== 'api_quiz'
          )
        ) {
          startTopic(user.sub, course?.id, topic?.id)

          const newCourse = {
            ...course,
            topics: course.topics.map((t) => {
              return t?.id === topic?.id
                ? {
                    ...t,
                    ...{ isStarted: true }
                  }
                : t
            })
          }

          queryClient.setQueryData(['course', courseId], newCourse)
          setCourse(newCourse)
        }
      }

      setTotals(course)
    },
    enabled: !!topic?.id
  })

  const useTopic = (courseId, topicId) => {
    return useQuery(topicQuery(courseId, topicId))
  }

  const { status, error, data: topicData } = useTopic(courseId, id)
  const { status: courseStatus, data: courseData } = useQuery(
    courseQuery(courseId)
  )

  useEffect(() => {
    let mounted = true

    if (mounted) {
      console.log('usefeect loaded')
      setCourse(courseData)
      setTopic(topicData)
    }

    if (sessionStorage.getItem('previewMode')) setPreviewMode(true)

    return () => {
      mounted = false
    }
  }, [id, courseId, courseData, topicData])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [id])

  return (
    <>
      {error?.response?.status === 404 ? (
        <Navigate to="/404" replace />
      ) : undefined}

      <HeroBanner height={headerHeight}>
        <></>
        {previewMode ? (
          <Typography
            variant="h5"
            color="secondary.light"
            sx={{
              mb: 1,
              mt: 0
            }}
          >
            Preview Mode
          </Typography>
        ) : undefined}

        {course &&
        topic &&
        status === 'success' &&
        courseStatus === 'success' ? (
          <>
            <Typography
              variant="eyebrow"
              color="secondary.light"
              sx={{
                mb: 1,
                mt: 0
              }}
            >
              {course.name}
            </Typography>
            <Typography
              variant="h1"
              color="white"
              sx={{
                mb: 3
              }}
            >
              {topic.name}
            </Typography>
            <Typography variant="body" paragraph color="white">
              {topic.description}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 3 }}>
              <AccessTimeIcon sx={{ color: '#fff', mr: 1 }} />
              <Typography variant="body" color="white">
                {topic.estimatedTime} min
              </Typography>
            </Box>
          </>
        ) : (
          <>
            <Box
              className="is-loading"
              sx={{
                minHeight: '40px',
                width: '350px',
                mb: 1
              }}
            ></Box>
            <Box
              className="is-loading"
              sx={{
                minHeight: '70px',
                width: '550px',
                mb: 3
              }}
            ></Box>
            <Box
              className="is-loading"
              sx={{
                minHeight: '30px',
                width: '80px'
              }}
            ></Box>
          </>
        )}
      </HeroBanner>

      {authed && isConfirmed ? (
        <Container component="section" maxWidth="xl">
          <BreadCrumb />
          <Paper
            elevation={3}
            sx={{
              backgroundColor: grey[100],
              display: { xs: 'flex', md: 'none' },
              justifyContent: 'space-between',
              alignItems: 'center',
              px: 2,
              mb: 2
            }}
          >
            <Typography
              sx={{
                textDecoration: 'uppercase'
              }}
            >
              SPENT
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', my: 1 }}>
              <AccessTimeIcon sx={{ mr: 1 }} />
              <Typography variant="body">{timeSpent} min</Typography>
            </Box>
            <Typography
              sx={{
                textDecoration: 'uppercase',
                textAlign: 'right'
              }}
            >
              LEFT
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
                my: 1
              }}
            >
              <AccessTimeIcon sx={{ mr: 1 }} />
              <Typography variant="body">{timeLeft} min</Typography>
            </Box>
          </Paper>
          <Grid
            container
            justifyContent="center"
            columnSpacing={{ xs: 2, sm: 1, lg: 8, xl: 7 }}
          >
            <Grid item xs={12} md={7} xl={7}>
              {status === 'loading' ? (
                <Paper
                  elevation={0}
                  className={('topic-card', 'is-loading')}
                  sx={{
                    p: 1,
                    my: 2,
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      p: 2,
                      opacity: 0.25
                    }}
                  >
                    Loading...
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      opacity: 0.25,
                      height: 325
                    }}
                  ></Box>
                </Paper>
              ) : status === 'error' ? (
                <span>Error: {error.message}</span>
              ) : topic?.attachments && courseStatus === 'success' ? (
                topic?.attachments
                  .filter((a) => a?.id !== undefined)
                  .map((a) => (
                    <Box key={a?.id} sx={{ my: 1 }}>
                      <GlobalStyles
                        styles={(theme) => {
                          return {
                            ...theme.typography,
                            ...{
                              body: theme.typography.topicBody,
                              img: {
                                height: 'auto',
                                width: '100%'
                              },
                              a: {
                                color: theme.palette.primary.main
                              }
                            }
                          }
                        }}
                      />
                      {(a.kind === 'xapi' || a.kind === 'xapi_quiz') &&
                        a.uri &&
                        course?.id && (
                          <>
                            <TopicXapi
                              courseId={course?.id}
                              endPoint={topicEndpoint}
                              topicId={topic?.id}
                              attachment={a}
                              userId={!previewMode ? user.sub : null}
                              userEmail={!previewMode ? user.email : null}
                              preview={previewMode}
                              callback={handleXapiReload}
                            />
                          </>
                        )}

                      {a.kind === 'text' || a.kind === 'code_embed' ? (
                        <Box
                          dangerouslySetInnerHTML={{
                            __html: formatHTml(a.html)
                          }}
                        ></Box>
                      ) : undefined}

                      {a.kind === 'pdf_embed' ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Box>
                            <TopicPdf attachment={a} />
                          </Box>
                        </Box>
                      ) : undefined}
                      {a.kind === 'video' ? (
                        <Box>
                          <TopicVideo attachment={a} onEnded={handleVideoEnd} />
                          {videoCompleted &&
                          topic?.attachments?.find((a) => a?.kind === 'text')
                            ? completeOrContinueButtons()
                            : undefined}
                        </Box>
                      ) : undefined}
                    </Box>
                  ))
              ) : undefined}

              {topic?.attachments?.find((a) => a?.kind === 'xapi_quiz') ||
              topic?.attachments?.find(
                (a) => a?.kind === 'xapi' && !topic?.isCompleted
              )
                ? undefined
                : completeOrContinueButtons()}
            </Grid>
            <Grid item xs={12} md={1} xl={1}></Grid>
            <Grid
              item
              xs={12}
              md={4}
              xl={3}
              sx={{ display: { xs: 'none', md: 'initial' } }}
            >
              <Paper
                sx={{
                  my: 0,
                  position: 'sticky',
                  top: '70px'
                }}
              >
                <Box
                  sx={{
                    py: { xs: 1, xl: 2 },
                    backgroundColor: '#13008B',
                    textAlign: 'center',
                    borderRadius: '4px 4px 0 0 '
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      textTransform: 'uppercase',
                      color: 'white'
                    }}
                  >
                    Course Progress
                  </Typography>
                </Box>

                <Box
                  sx={{
                    py: { xs: 1, lg: 1, xl: 3 },
                    px: { xs: 2, xl: 3 },
                    backgroundColor: grey[100]
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                      mt: { xs: 1, xl: 0 },
                      mb: { xs: 0, xl: 2 }
                    }}
                  >
                    <Box sx={{ display: { xs: 'flex', lg: 'none' } }}>
                      <CourseProgressCircle
                        percent={
                          course?.topics
                            ? (totalCompletedTopics / course.topics.length) *
                              100
                            : 0
                        }
                        size={90}
                        strokeWidth={3}
                      />
                    </Box>
                    <Box
                      sx={{ display: { xs: 'none', lg: 'flex', xl: 'none' } }}
                    >
                      <CourseProgressCircle
                        percent={
                          course?.topics
                            ? (totalCompletedTopics / course.topics.length) *
                              100
                            : 0
                        }
                        size={100}
                        strokeWidth={3}
                      />
                    </Box>
                    <Box sx={{ display: { xs: 'none', xl: 'flex' } }}>
                      <CourseProgressCircle
                        percent={
                          course?.topics
                            ? (totalCompletedTopics / course.topics.length) *
                              100
                            : 0
                        }
                        size={120}
                        strokeWidth={3}
                      />
                    </Box>
                  </Box>
                  <Grid container justifyContent="center">
                    <Grid item xs={12} md={5}>
                      <Typography
                        sx={{
                          textDecoration: 'uppercase'
                        }}
                      >
                        SPENT
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          my: { xs: 1, md: '2px', xl: 1 }
                        }}
                      >
                        <AccessTimeIcon sx={{ mr: 1 }} />
                        <Typography variant="body">{timeSpent} min</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={1}></Grid>
                    <Grid item xs={12} md={5}>
                      <Typography
                        sx={{
                          textDecoration: 'uppercase',
                          textAlign: 'right'
                        }}
                      >
                        LEFT
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'end',
                          my: { xs: 1, md: '2px', xl: 1 }
                        }}
                      >
                        <AccessTimeIcon sx={{ mr: 1 }} />
                        <Typography variant="body">{timeLeft} min</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Typography
                    variant="h5"
                    sx={{
                      textTransform: 'uppercase',
                      textDecoration: 'underline',
                      fontWeight: 600,
                      textAlign: 'center',
                      my: { xs: 2, md: '4px', xl: 4 }
                    }}
                  >
                    Topics Covered ({totalCompletedTopics}/
                    {course?.topics ? course.topics.length : 0})
                  </Typography>
                  {course?.topics
                    ? course.topics
                        .filter((t) => t?.id !== undefined)
                        .map((t) => (
                          <Box
                            key={t?.id}
                            component={Link}
                            to={`/courses/${course.slug}/${t.slug}`}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              my: { xs: 1, md: '2px', xl: 1 },
                              textTransform: 'uppercase',
                              textDecoration: 'none',
                              ':hover': {
                                color: 'secondary',
                                textDecoration: 'underline'
                              }
                            }}
                          >
                            <Typography
                              component="h6"
                              sx={{
                                color:
                                  status === 'success' && topic?.id === t?.id
                                    ? 'secondary.main'
                                    : '',
                                textDecoration:
                                  status === 'success' && topic?.id === t?.id
                                    ? 'underline'
                                    : '',
                                fontSize: '.9rem'
                              }}
                            >
                              <Box
                                sx={{
                                  display: {
                                    xs: 'none',
                                    md: 'flex',
                                    lg: 'none'
                                  }
                                }}
                              >
                                <Truncate lines={1} width={152}>
                                  {t.name}
                                </Truncate>
                              </Box>
                              <Box
                                sx={{
                                  display: {
                                    xs: 'none',
                                    lg: 'flex',
                                    xl: 'none'
                                  }
                                }}
                              >
                                <Truncate lines={1} width={200}>
                                  {t.name}
                                </Truncate>
                              </Box>
                              <Box
                                sx={{
                                  display: {
                                    xs: 'none',
                                    xl: 'flex'
                                  }
                                }}
                              >
                                <Truncate lines={1} width={180}>
                                  {t.name}
                                </Truncate>
                              </Box>
                            </Typography>
                            {status === 'success' ? (
                              <TopicStatusCircle
                                topic={t}
                                topicId={topic?.id}
                                courseId={courseId}
                                previewMode={previewMode}
                              />
                            ) : undefined}
                          </Box>
                        ))
                    : [...Array(8)].map((_, i) => (
                        <Paper
                          key={`empty-topic-card-${i}`}
                          elevation={0}
                          className={('topic-card', 'is-loading')}
                          sx={{
                            p: 1,
                            my: 1,
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              width: '100%',
                              alignItems: 'center',
                              opacity: 0.25
                            }}
                          >
                            <Typography
                              variant="h5"
                              sx={{
                                textAlign: 'center',
                                opacity: 0.25
                              }}
                            >
                              Topic
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              opacity: 0.25
                            }}
                          >
                            <CircleOutlinedIcon
                              color="disabled"
                              sx={{ ml: 3 }}
                            />
                          </Box>
                        </Paper>
                      ))}
                </Box>
              </Paper>
            </Grid>
          </Grid>
          {course?.id ? (
            <WhatsNextCourses courseId={course?.id} authed={authed} />
          ) : undefined}
        </Container>
      ) : undefined}
    </>
  )
}
