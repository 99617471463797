import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useAuth } from './auth'
import saLogo from './super-awesome-logo-colored-218.png'
import arrowDown from './arrow-down.png'
import ButtonModal from '../common/ButtonModal'
import SignUpForm from '../account/SignUpForm'
import ForgotPasswordForm from '../account/ForgotPasswordForm'
import ConfirmPasswordForm from './ConfirmPasswordForm'
import {
  Button,
  TextField,
  Box,
  Typography,
  Alert,
  Link,
  Grid,
  Container,
  Divider
} from '@mui/material'
function LoginForm({ returnPath }) {
  const [showAlert, setShowAlert] = useState(null)
  const [logginIn, setLoggingIn] = useState(false)
  const [allowSubmit, setAllowSubmit] = useState(false)
  const [allowLogin, setAllowLogin] = useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
  const [challengeResponse, setChallengeResponse] = useState({})
  const [email, setEmail] = useState(null)
  const auth = useAuth()
  const navigate = useNavigate()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleConfirmAccountFinalization = (message) => {
    setShowPasswordConfirm(false)
    if (message) setShowAlert(message)
  }

  const handleEmail = (e) => {
    setEmail(e.target.value.trim())
    setAllowSubmit(true)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    setShowAlert(false)

    if (!allowLogin) {
      setAllowLogin(true)
      return false
    }

    setLoggingIn(true)

    const data = new FormData(e.currentTarget)

    try {
      const loginToken = await executeRecaptcha('loginToken')
      const response = await auth.login(
        data.get('email').trim(),
        data.get('password').trim(),
        loginToken
      )

      if (
        response?.challengeName &&
        response.challengeName === 'NEW_PASSWORD_REQUIRED'
      ) {
        setShowPasswordConfirm(true)
        setChallengeResponse(response)
      }

      setLoggingIn(false)
      if (returnPath) navigate(returnPath)
    } catch (e) {
      setLoggingIn(false)
      setShowAlert(e.message)
      console.error(e)
    }
  }

  return (
    <Container component="main">
      <Grid
        container
        rowSpacing={{ xs: 4, lg: 0 }}
        columnSpacing={{ xs: 0, lg: 3 }}
      >
        <Grid item xs={12} lg={5}>
          {showAlert ? (
            <Alert variant="outlined" severity="warning" sx={{ my: 2 }}>
              {showAlert}
            </Alert>
          ) : undefined}
          {!showPasswordConfirm ? (
            <Box component="form" onSubmit={handleSubmit}>
              {!showPasswordConfirm ? (
                <Typography
                  component="h1"
                  variant="h5"
                  sx={{ textAlign: 'center', textTransform: 'uppercase' }}
                >
                  Access Kidaware training
                </Typography>
              ) : undefined}

              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Work Email"
                type="email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleEmail}
              />
              {allowLogin ? (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
              ) : null}

              <Button
                type="submit"
                fullWidth
                disabled={logginIn || !allowSubmit}
                variant="contained"
                sx={{ mt: 3, mb: 1 }}
              >
                {logginIn ? (
                  <span>Logging in ...</span>
                ) : (
                  <span> {allowLogin ? <>Continue</> : <>Log in</>}</span>
                )}
              </Button>
              {!allowLogin ? (
                <>
                  <Typography variant="body2" sx={{ textAlign: 'center' }}>
                    OR
                  </Typography>
                  <ButtonModal
                    component={
                      <Button
                        variant="contained"
                        fullWidth
                        id="sign-up-link"
                        disabled={logginIn || !allowSubmit}
                        sx={{ mt: 1, mb: 2 }}
                      >
                        {'Sign Up'}
                      </Button>
                    }
                  >
                    <SignUpForm initialEmail={email} />
                  </ButtonModal>
                </>
              ) : (
                <Button
                  fullWidth
                  disabled={logginIn || !allowSubmit}
                  variant="contained"
                  sx={{ mb: 2 }}
                  onClick={() => setAllowLogin(false)}
                >
                  Cancel
                </Button>
              )}

              <Box
                sx={{
                  textAlign: 'center'
                }}
              >
                <ButtonModal
                  component={
                    <Link href="" variant="body2" id="forgot-password-link">
                      {'Forgot password?'}
                    </Link>
                  }
                >
                  <ForgotPasswordForm />
                </ButtonModal>
              </Box>
            </Box>
          ) : (
            <>
              <ConfirmPasswordForm
                user={challengeResponse}
                onConfirm={handleConfirmAccountFinalization}
              />
            </>
          )}
        </Grid>
        <Grid item xs={12} lg={2}>
          <Divider
            orientation="vertical"
            sx={{ display: { xs: 'none', lg: 'flex' } }}
          >
            OR
          </Divider>
          <Divider
            orientation="horizontal"
            sx={{ display: { xs: 'flex', lg: 'none' } }}
          >
            OR
          </Divider>
        </Grid>
        <Grid item xs={12} lg={5}>
          <Box
            sx={{
              textAlign: 'left',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%'
            }}
          >
            <Box sx={{ p: 2, display: 'flex', alignSelf: 'center' }}>
              <Box sx={{ textAlign: 'center' }}>
                <img
                  src={saLogo}
                  width="218px"
                  height="112px"
                  alt="Super Awesome Logo"
                />
                <Box sx={{ pt: 1 }}></Box>
                <img
                  src={arrowDown}
                  width="100px"
                  height="22px"
                  alt="arrow down"
                />
              </Box>
            </Box>
            <Button
              type="submit"
              onClick={() => auth.loginOktaUrl()}
              fullWidth
              variant="outlined"
              sx={{ mb: 2 }}
            >
              Log in with Okta
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

LoginForm.propTypes = {
  returnPath: PropTypes.string,
  closeModal: PropTypes.func
}

export default LoginForm
